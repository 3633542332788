import Planner from "@/api/WebPlanner";
import Catalog from "@/api/Catalog";
import Module from "@/module/Module";
import { ApiResponse } from "@/api/Response";
import { notify } from "@kyvg/vue3-notification";
import Lang from "@/plugins/Lang";
import User from "@/module/User";
class WebPlanner extends Module {
    checkProd() {
        if (sessionStorage.getItem("prod-confirm") === "1") {
            return true;
        }
        else {
            const confirmAction = (User.getMe().instanceProd &&
                confirm("Вы используете продуктивную версию. Вы действительно хотите продолжить? ")) ||
                !User.getMe().instanceProd;
            if (confirmAction) {
                sessionStorage.setItem("prod-confirm", "1");
                return true;
            }
            else
                return false;
        }
    }
    async getCatalogList(instanceId = 0) {
        let response = null;
        await Planner.getCatalogList(instanceId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getRoles() {
        let response = null;
        await Planner.getRoles()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getDecodeProtobufMessage(message) {
        let response = null;
        await Planner.getDecodeProtobufMessage(message)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getInstances() {
        let response = null;
        await Planner.getInstances()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getImportHistory(historyId) {
        let response = null;
        await Planner.getImportHistory(historyId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getQueueState() {
        let response = null;
        await Planner.getQueueState()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            console.error(err);
        });
        return response;
    }
    async getImportHistoryMessages(historyId) {
        let response = null;
        await Planner.getInstances()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async createInstance(params) {
        let response = null;
        await Planner.createInstance(params)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async importMaterials(catalogId, params) {
        let response = null;
        if (this.checkProd()) {
            await Planner.importMaterials(catalogId, params)
                .then((res) => {
                response = res;
            })
                .catch((err) => {
                response = this.notifyError(err);
            });
        }
        return response;
    }
    async syncDatabase(instanceId, catalogId = 0) {
        let response = null;
        await Planner.syncDatabase(instanceId, catalogId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async syncCatalog(instanceId, catalogId, folderId = 0) {
        let response = null;
        await Planner.syncDatabase(instanceId, catalogId, folderId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async syncPropertyDatabase(instanceId) {
        let response = null;
        await Planner.syncPropertyDatabase(instanceId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async syncModelsDatabase(instanceId, catalogId = 0, folderId = 0) {
        let response = null;
        await Planner.syncModelsDatabase(instanceId, catalogId, folderId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async syncModelDatabase(instanceId, catalogId, folderId, modelId) {
        let response = null;
        await Planner.syncModelsDatabase(instanceId, catalogId, folderId, modelId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getCatalogDetail(itemId, folderId, page, instanceId = 0, q = "") {
        let response = null;
        await Planner.getCatalogDetail(itemId, folderId, page, instanceId, q)
            .then((res) => {
            response = res;
        })
            .catch((err) => { });
        return response;
    }
    async getCatalogProperties(catalogId, instanceId = 0, p = 1, q = "") {
        let response = null;
        instanceId = instanceId == 0 ? User.getMe().instanceId : instanceId;
        await Planner.getCatalogProperties(catalogId, instanceId, p, q)
            .then((res) => {
            response = res;
        })
            .catch((err) => { });
        return response;
    }
    async getCatalogModels(catalogId, parentId = "0", full = true) {
        let response = null;
        console.log("parentId", parentId);
        await Planner.getCatalogModels(catalogId, parentId, full)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            // response = this.notifyError(err);
            response = err;
        });
        return response;
    }
    async copyModelFolder(catalogId, folderId, data) {
        let response = null;
        await Planner.copyModelFolder(catalogId, folderId, data)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async copyModelFolderStatus(copyId) {
        let response = null;
        await Planner.copyModelFolderStatus(copyId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getCatalogModel(catalogId, parentId = "0") {
        let response = null;
        await Planner.getCatalogModel(catalogId, parentId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getCatalogPropertyParams(catalogId = "0") {
        let response = null;
        await Planner.getCatalogPropertyParams(catalogId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getCatalogProperty(catalogId, propertyId) {
        let response = null;
        await Planner.getCatalogProperty(catalogId, propertyId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async reSyncProperty(catalogId, propertyId) {
        let response = null;
        await Planner.reSyncProperty(catalogId, propertyId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getCatalogPropertyModels(catalogId, propertyId) {
        let response = null;
        await Planner.getCatalogPropertyModels(catalogId, propertyId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getCatalogPropertySyncStatus(catalogId, propertyId) {
        let response = null;
        await Planner.getCatalogPropertySyncStatus(catalogId, propertyId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async findMaterials(params, catalogId = 0) {
        let response = null;
        await Planner.findMaterials(params, catalogId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async updateCatalogProperty(catalogId, propertyId, data) {
        let response = null;
        await Planner.updateCatalogProperty(catalogId, propertyId, data)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            notify({
                title: Lang.get("globals.default_error_title"),
                type: "error",
                text: err.getMessage().m,
            });
        });
        return response;
    }
    async updateModelProperties(catalogId, modelId, data) {
        let response = null;
        await Planner.updateModelProperties(catalogId, modelId, data)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            notify({
                title: Lang.get("globals.default_error_title"),
                type: "error",
                text: err.getMessage().m,
            });
        });
        return response;
    }
    async createCatalogProperty(catalogId, data) {
        let response = null;
        await Planner.createCatalogProperty(catalogId, data)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = err;
            //response = this.notifyError(err);
        });
        return response;
    }
    async getItemDetail(itemId, name = "", catalogId = 0) {
        let response = null;
        await Planner.getItemDetail(itemId, name, catalogId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getCatalogHistory(catalogId, instanceId) {
        let response = null;
        await Planner.getCatalogHistory(catalogId, instanceId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async setMaterial(materialId, data, removeBump = false) {
        let response = null;
        if (this.checkProd()) {
            await Planner.setMaterial(materialId, data, removeBump)
                .then((res) => {
                response = res;
                notify({
                    title: Lang.get("globals.default_success_title"),
                    type: "success",
                    text: "",
                });
            })
                .catch((err) => {
                response = this.notifyError(err);
            });
        }
        return response;
    }
    async setFiles(materialId, data) {
        let response = null;
        await Planner.setFiles(materialId, data)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async setBumpFromTexture(materialId) {
        let response = null;
        await Planner.setBumpFromTexture(materialId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async deleteMaterial(materialId, catalogId) {
        let response = null;
        if (this.checkProd()) {
            await Planner.deleteMaterial(materialId, catalogId)
                .then((res) => {
                response = res;
                notify({
                    title: Lang.get("globals.default_success_title"),
                    type: "success",
                    text: "",
                });
            })
                .catch((err) => {
                response = this.notifyError(err);
            });
        }
        return response;
    }
    async deleteProperty(propertyId, catalogId) {
        let response = null;
        if (this.checkProd()) {
            await Planner.deleteProperty(propertyId, catalogId)
                .then((res) => {
                response = res;
                notify({
                    title: Lang.get("globals.default_success_title"),
                    type: "success",
                    text: "",
                });
            })
                .catch((err) => {
                response = this.notifyError(err);
            });
        }
        return response;
    }
    async fixMaterial(materialId, catalogId) {
        let response = null;
        if (this.checkProd()) {
            await Planner.fixMaterial(materialId, catalogId)
                .then((res) => {
                response = res;
                notify({
                    title: Lang.get("globals.default_success_title"),
                    type: "success",
                    text: "",
                });
            })
                .catch((err) => {
                response = this.notifyError(err);
            });
        }
        return response;
    }
    async getHandbookParams(queryString = "") {
        let response = null;
        await Planner.getHandbookParams(queryString)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getPropertyGroups() {
        let response = new ApiResponse("", "");
        await Planner.getPropertyGroups()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getPropertyModels() {
        let response = new ApiResponse("", "");
        await Planner.getPropertyModels()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getAttributes() {
        let response = new ApiResponse("", "");
        await Planner.getAttributes()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async setAttribute(name, code) {
        let response = new ApiResponse("", "");
        await Planner.setAttribute({
            name: name,
            code: code,
            id: "0",
        })
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async setPropertyGroups(name, code) {
        let response = new ApiResponse("", "");
        await Planner.setPropertyGroups({
            name: name,
            code: code,
            id: "0",
        })
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async setPropertyModel(name, code, type, values = []) {
        let response = new ApiResponse("", "");
        await Planner.setPropertyModels({
            id: "0",
            name: name,
            code: code,
            type: type,
            values: values,
        })
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async getHandbookParam(paramId) {
        let response = null;
        await Planner.getHandbookParam(paramId)
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async addHandbookParam(paramName, paramDesc) {
        let response = null;
        await Planner.addHandbookParam(paramName, paramDesc)
            .then((res) => {
            response = res;
            notify({
                title: Lang.get("globals.default_success_title"),
                type: "success",
                text: "",
            });
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async updateHandbookParam(id, paramName) {
        let response = null;
        await Planner.updateHandbookParam(id.toString(), paramName)
            .then((res) => {
            response = res;
            notify({
                title: Lang.get("globals.default_success_title"),
                type: "success",
                text: "",
            });
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async setModelPropertyRow(catalogId, modelId, linkId, rowId) {
        let response = null;
        await Planner.setModelPropertyRow(catalogId, modelId, linkId, rowId)
            .then((res) => {
            response = res;
            notify({
                title: Lang.get("globals.default_success_title"),
                type: "success",
                text: "",
            });
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
    async loadPriceList(catalogId, asFile) {
        return await Catalog.getPriceList(catalogId, asFile);
    }
    async hideModel(externalId) {
        return Catalog.hideModel(externalId);
    }
    async shareModel(externalId) {
        return Catalog.shareModel(externalId);
    }
    async testRequest() {
        let response = null;
        await Planner.testRequest()
            .then((res) => {
            response = res;
        })
            .catch((err) => {
            response = this.notifyError(err);
        });
        return response;
    }
}
export default new WebPlanner();
