// import CreateElement from "@/binaplan/views/handbooks/nomenclature/ElementCreateView.vue";
class main {
    route;
    constructor() {
        this.route = [
            {
                path: "/",
                name: "web-planner",
                meta: { auth: true, section: "web-planner" },
                component: () => import("@/binaplan/views/planner/CatalogListView.vue"),
            },
            {
                path: "/handbooks/properties/models",
                name: "properties-models",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/planner/model-properties/ModelList.vue"),
            },
            {
                path: "/handbooks/properties/models/add",
                name: "properties-models-add",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/planner/model-properties/ModelForm.vue"),
            },
            {
                path: "/handbooks/properties/groups",
                name: "properties-groups",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/planner/properties/GroupList.vue"),
            },
            {
                path: "/handbooks/properties/groups/add",
                name: "properties-group-add",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/planner/properties/GroupForm.vue"),
            },
            {
                path: "/handbooks/attributes",
                name: "attributes",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/planner/attributes/GroupList.vue"),
            },
            {
                path: "/handbooks/attributes/add",
                name: "attributes-add",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/planner/attributes/GroupForm.vue"),
            },
            {
                path: "/profile/setting",
                name: "profile-setting",
                meta: { auth: true, section: "account" },
                component: () => import("@/binaplan/views/user/SettingsView.vue"),
            },
            {
                path: "/websocket-decoder",
                name: "websocket-decoder",
                meta: { auth: true, section: "test" },
                component: () => import("@/binaplan/views/WebSocketDecoder.vue"),
            },
            {
                path: "/test-question-form",
                name: "test-question-form",
                meta: { auth: true, section: "test" },
                component: () => import("@/binaplan/views/TestQuestionForm.vue"),
            },
            {
                path: "/materials/:id",
                name: "materials-detail",
                meta: { auth: true, section: "web-planner-test", absolutePage: true },
                component: () => import("@/binaplan/views/planner/CatalogDetailView.vue"),
            },
            {
                path: "/materials/:id/materials/:folderId",
                name: "materials-folder-detail",
                meta: { auth: true, section: "web-planner-test", absolutePage: true },
                component: () => import("@/binaplan/views/planner/CatalogDetailView.vue"),
            },
            {
                path: "/materials/:id/materials/",
                name: "materials-folder-empty-detail",
                meta: { auth: true, section: "web-planner-test", absolutePage: true },
                component: () => import("@/binaplan/views/planner/CatalogDetailView.vue"),
            },
            {
                path: "/materials/:id/properties",
                name: "materials-property-list",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/CatalogDetailWithPropertiesView.vue"),
            },
            {
                path: "/materials/:id/pricelist",
                name: "catalog-pricelist",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/CatalogPriceListView.vue"),
            },
            {
                path: "/materials/:id/models",
                name: "materials-model-list",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/CatalogDetailWithModelsView.vue"),
            },
            {
                path: "/materials/:id/models/:folderId",
                name: "materials-model-children-list",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/CatalogDetailWithModelsView.vue"),
            },
            {
                path: "/materials/:id/model/:modelId",
                name: "materials-model-children-detail",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/CatalogDetailWithModelView.vue"),
            },
            {
                path: "/materials/:id/properties/add",
                name: "materials-property-add",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/CatalogPropertyAddView.vue"),
            },
            {
                path: "/materials/:id/properties/:propertyId",
                name: "materials-property-detail",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/CatalogPropertyView.vue"),
            },
            {
                path: "/materials/:id/properties/:propertyId/:action",
                name: "materials-property-copy",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/CatalogPropertyView.vue"),
            },
            {
                path: "/materials/:id/import",
                name: "materials-detail-import",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/CatalogImport.vue"),
            },
            {
                path: "/materials/:catalogId/import/histories/:historyId",
                name: "import-materials-histories-detail",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/HistoryCatalogDetail.vue"),
            },
            {
                path: "/materials/:catalogId/import/histories/",
                name: "import-materials-histories",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/HistoryCatalogList.vue"),
            },
            {
                path: "/import/histories/",
                name: "import-histories",
                meta: { auth: true, section: "web-planner-test" },
                component: () => import("@/binaplan/views/planner/HistoryList.vue"),
            },
            {
                path: "/handbooks/params/",
                name: "params",
                meta: { auth: true, section: "params" },
                component: () => import("@/binaplan/views/planner/handbook/ListView.vue"),
            },
            {
                path: "/auto-kitchens/materials",
                name: "auto-kitchens_catalog",
                meta: { auth: true, section: "auto-kitchens" },
                component: () => import("@/binaplan/views/auto-kitchens/catalogs/CatalogsListView.vue"),
            },
            {
                path: "/auto-kitchens/survey-configurator",
                name: "auto-kitchens_survey-configurator",
                meta: { auth: true, section: "auto-kitchens" },
                component: () => import("@/binaplan/views/auto-kitchens/surveyConfigurator/SurveyConfiguratorView.vue"),
            },
            {
                path: "/auto-kitchens/collection/create",
                name: "new-collection",
                meta: { auth: true, section: "auto-kitchens" },
                component: () => import("@/binaplan/views/auto-kitchens/collections/NewCollectionView.vue"),
            },
            {
                path: "/auto-kitchens/collection/:id",
                name: "collection",
                meta: { auth: true, section: "auto-kitchens" },
                component: () => import("@/binaplan/views/auto-kitchens/collections/CollectionView.vue"),
            },
            {
                path: "/handbooks/params/add",
                name: "handbooks-params-add",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/planner/handbook/FormView.vue"),
            },
            {
                path: "/handbooks/fasteners/company",
                name: "handbooks",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/company/ListView.vue"),
            },
            {
                path: "/handbooks/fasteners/company",
                name: "company",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/company/ListView.vue"),
            },
            {
                path: "/handbooks/fasteners/company/add",
                name: "addCompany",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/company/FormView.vue"),
            },
            {
                path: "/handbooks/fasteners/company/edit/:id",
                name: "editCompany",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/company/FormView.vue"),
            },
            {
                path: "/handbooks/fasteners/attributes",
                name: "fasteners-attributes",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/attributes/ListView.vue"),
            },
            {
                path: "/handbooks/fasteners/attributes/add",
                name: "fasteners-addAttributes",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/attributes/FormView.vue"),
            },
            {
                path: "/handbooks/fasteners/attributes/edit/:id",
                name: "fasteners-editAttributes",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/attributes/FormView.vue"),
            },
            {
                path: "/handbooks/materials",
                name: "handbooks-materials",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/planner/materials/CatalogView.vue"),
            },
            {
                path: "/handbooks/materials/:id",
                name: "handbooks-materials-detail",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/planner/materials/CatalogView.vue"),
            },
            {
                path: "/handbooks/marks/groups",
                name: "handbooks-marks-groups",
                meta: { auth: true, section: "handbooks" },
                component: () => import("../../views/handbooks/marks/GroupList.vue"),
            },
            {
                path: "/handbooks/materials/properties",
                name: "handbooks-materials-properties",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/planner/materials/PropertiesView.vue"),
            },
            {
                path: "/handbooks/materials/properties/:id",
                name: "handbooks-materials-properties-detail",
                meta: { auth: true, section: "handbooks" },
                component: () => 
                // import("@/binaplan/views/planner/materials/PropertyForm.vue"),
                import("@/binaplan/views/planner/materials/PropertiesView.vue"),
            },
            {
                path: "/handbooks/fasteners/matching",
                name: "matching",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/matching/ListView.vue"),
            },
            {
                path: "/handbooks/fasteners/matching/add",
                name: "addComparisons",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/matching/FormView.vue"),
            },
            {
                path: "/handbooks/fasteners/matching/edit/:id",
                name: "editComparisons",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/matching/FormView.vue"),
            },
            {
                path: "/handbooks/fasteners/fastening",
                name: "fastening",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/fastening/ListView.vue"),
            },
            {
                path: "/handbooks/fasteners/fastening_params",
                name: "fastening-params",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/fastening/ListView.vue"),
            },
            {
                path: "/handbooks/fasteners/fastening/add",
                name: "addFastening",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/fastening/FormView.vue"),
            },
            {
                path: "/handbooks/fasteners/fastening/edit/:id",
                name: "editFastening",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/directory/fastening/FormView.vue"),
            },
            {
                path: "/anchors",
                name: "anchors",
                meta: { auth: true, section: "anchors" },
                component: () => import("@/binaplan/views/planner/AnchorListView.vue"),
            },
            {
                path: "/handbooks/anchors",
                name: "handbooks-anchors",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/handbooks/anchor/ListView.vue"),
            },
            {
                path: "/handbooks/anchors/create",
                name: "handbooks-anchors-create",
                meta: { auth: true, section: "handbooks" },
                component: () => import("@/binaplan/views/handbooks/anchor/FormView.vue"),
            },
            {
                path: "/account",
                name: "account",
                meta: { auth: true },
                component: () => import("../../views/AccountView.vue"),
            },
            {
                path: "/login",
                name: "login",
                component: () => import("../../views/user/LoginView.vue"),
            },
            {
                path: "/signup",
                name: "signup",
                component: () => import("../../views/user/SignupView.vue"),
            },
            {
                path: "/test/api",
                name: "test-api",
                meta: { auth: true, section: "handbooks" },
                component: () => import("../../views/TestApi.vue"),
            },
            {
                path: "/test/view",
                name: "test-view",
                component: () => import("../../views/TestView.vue"),
            },
            {
                path: "/test/modal",
                name: "test-view",
                component: () => import("../../views/TestModal.vue"),
            },
            {
                path: "/admin/dashboard",
                name: "admin",
                component: () => import("../../views/admin/pages/DashboardView.vue"),
            },
            {
                path: "/logout",
                name: "logout",
                component: () => { },
            },
            {
                path: "/404",
                name: "404",
                component: () => import("../../views/errors/NotFoundView.vue"),
            },
        ];
    }
}
export default new main();
