import defaultRequest from "@/api/DefaultRequest";
class Catalog {
    constructor() { }
    createProperty(params) {
        return defaultRequest.post("catalog/properties/add", params);
    }
    getCatalog(catalogId) {
        return defaultRequest.get("catalog/" + catalogId);
    }
    getVariantPropertyList(catalogId) {
        return defaultRequest.get("catalog/" + catalogId + "/variant-properties/");
    }
    getVariantProperty(catalogId, propertyId) {
        return defaultRequest.get("catalog/" + catalogId + "/variant-properties/" + propertyId);
    }
    createVariantProperty(catalogId, params) {
        return defaultRequest.post("catalog/" + catalogId + "/variant-properties", params);
    }
    deleteVariantProperty(propertyId) {
        const params = { _method: "DELETE" };
        return defaultRequest.post("catalog/variant-properties/" + propertyId, params);
    }
    getProperty(propertyId) {
        return defaultRequest.get("catalog/properties/" + propertyId);
    }
    deleteProperty(propertyId) {
        const params = { _method: "DELETE" };
        return defaultRequest.post("catalog/properties/" + propertyId, params);
    }
    addModelsFolder(catalogId, folderName, parentId) {
        return defaultRequest.post(`web-planner/backend/catalogs/${catalogId}/folders`, {
            folderName: folderName,
            parentId: parentId || 0,
        }, true);
    }
    uploadModels(catalogId, files, parentId) {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append("files[]", files[i]);
        }
        formData.append("catalogId", catalogId);
        formData.append("parentId", parentId);
        return defaultRequest.post(`web-planner/backend/catalogs/${catalogId}/model`, formData, true);
    }
    getPriceList(catalogId, asFile) {
        let url = `web-planner/backend/catalogs/${catalogId}/pricelist`;
        if (asFile) {
            url += "?format=xlsx";
            return defaultRequest.downloadFile("api/" + url);
        }
        else {
            return defaultRequest.get(url);
        }
    }
    importPriceList(catalogId, file) {
        const formData = new FormData();
        formData.append("pricelist", file);
        return defaultRequest.post(`web-planner/backend/catalogs/${catalogId}/pricelist`, formData, true);
    }
    getPriceListStatuses(catalogId, page) {
        const pageNumber = page || 1;
        return defaultRequest.get(`web-planner/backend/catalogs/${catalogId}/pricelist-status/?page=${pageNumber}`);
    }
    hideModel(externalId) {
        return defaultRequest.post(`web-planner/backend/catalogs/hide/${externalId}/`, {});
    }
    shareModel(externalId) {
        return defaultRequest.post(`web-planner/backend/catalogs/share/${externalId}/`, {});
    }
}
export default new Catalog();
