import request from "@/api/Request";
import { ApiResponse } from "@/api/Response";
import axios from "axios";
class DefaultRequest {
    constructor() { }
    get(url) {
        return new Promise(function (resolve, reject) {
            request
                .get(url)
                .then((res) => {
                if (typeof res === "undefined") {
                    return null;
                }
                else if (res.data.type == "success") {
                    resolve(new ApiResponse("success", res.data.data));
                }
                else if (res.data.type == "wait") {
                    resolve(new ApiResponse("wait", res.data.data));
                }
                else {
                    reject(new ApiResponse("error", "", res.data));
                }
            })
                .catch((err) => {
                reject(new ApiResponse("error", "", err));
            });
        });
    }
    delete(url) {
        return new Promise(function (resolve, reject) {
            request
                .delete(url)
                .then((res) => {
                if (typeof res === "undefined") {
                    return null;
                }
                else if (res.data.type == "success") {
                    resolve(new ApiResponse("success", res.data.data));
                }
                else if (res.data.type == "wait") {
                    resolve(new ApiResponse("wait", res.data.data));
                }
                else {
                    reject(new ApiResponse("error", "", res.data.data));
                }
            })
                .catch((err) => {
                reject(new ApiResponse("error", "", err));
            });
        });
    }
    init() {
        return new Promise(function (resolve, reject) {
            request
                .get("auth/me")
                .then((res) => {
                if (typeof res === "undefined") {
                    return null;
                }
                else if (res.data.type == "success") {
                    const response = new ApiResponse("success", res.data.data);
                }
                else {
                    reject(new ApiResponse("error", "", res.data.data));
                }
            })
                .catch((err) => {
                reject(new ApiResponse("error", "", err));
            });
        });
    }
    post(url, params, returnError = false) {
        return new Promise(function (resolve, reject) {
            request
                .post(url, params)
                .then((res) => {
                // console.log("post handler", res);
                //console.table({ ...res.data });
                res.data.type =
                    typeof res.data.type === "undefined" ? "error" : res.data.type;
                if (typeof res === "undefined") {
                    return null;
                }
                else if (res.data.type == "success" || res.data.type == "confirm") {
                    // if ("token" in res.data) {
                    //   resolve(
                    //     new ApiResponse(res.data.type ?? "error", res.data.token)
                    //   );
                    // } else {
                    resolve(new ApiResponse(res.data.type ?? "error", res.data.data));
                    // }
                }
                else {
                    reject(new ApiResponse("error", "", res.data.message ?? res.data.data));
                }
            })
                .catch((err) => {
                if (returnError) {
                    reject(new ApiResponse("error", "", err));
                }
            });
        });
    }
    postWithToken(url, params, returnError = false, token = "") {
        return new Promise(function (resolve, reject) {
            request
                .postWithToken(url, params, token)
                .then((res) => {
                res.data.type =
                    typeof res.data.type === "undefined" ? "error" : res.data.type;
                if (typeof res === "undefined") {
                    return null;
                }
                else if (res.data.type == "success" || res.data.type == "confirm") {
                    if ("token" in res.data) {
                        resolve(new ApiResponse(res.data.type ?? "error", res.data.token));
                    }
                    else {
                        resolve(new ApiResponse(res.data.type ?? "error", res.data.data));
                    }
                }
                else {
                    reject(new ApiResponse("error", "", res.data.message ?? res.data.data));
                }
            })
                .catch((err) => {
                if (returnError) {
                    reject(new ApiResponse("error", "", err));
                }
            });
        });
    }
    uploadFile(url, paramsForm) {
        return new Promise(function (resolve, reject) {
            request
                .uploadFile(url, paramsForm)
                .then((res) => {
                if (res.data.type == "success") {
                    resolve(new ApiResponse("success", res.data));
                }
                else {
                    reject(new ApiResponse("error", "", res.data.message));
                }
            })
                .catch((err) => {
                reject(new ApiResponse("error", "", err));
            });
        });
    }
    downloadFile(url) {
        return new Promise(function (resolve, reject) {
            const token = localStorage.getItem("token");
            axios
                .get(process.env.VUE_APP_BASE_HOST + url, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(async (response) => {
                console.log("Handle file response", response);
                let filename;
                if (response.headers && response.headers["content-disposition"]) {
                    const str = response.headers["content-disposition"];
                    const filenamePattern = /filename=['"](?<filename>[^'"]+)['"]/;
                    const groups = filenamePattern.exec(str);
                    console.log(groups);
                    filename =
                        (groups && groups.groups && groups.groups.filename) || "";
                }
                const blobUrl = URL.createObjectURL(response.data);
                resolve(new ApiResponse("success", blobUrl, {
                    file: response.data,
                    fileName: filename,
                }));
            })
                .catch((err) => {
                reject(new ApiResponse("error", "", "Не удалось скачать файл"));
            });
        });
    }
}
export default new DefaultRequest();
